<template>
  <div class="row">
    <CreateNewColModal
      ref="createNewColModal"
      :key="modalKey"
      :company="companyId"
      @success="handleCreatedCol"
    ></CreateNewColModal>
    <div class="col-12">
      <div class="card">
        <div class="card-header border-0 pt-5 px-6">
          <h3 class="card-title align-items-start flex-column">
            <span class="card-label fw-bolder fs-3 mb-1">Job Details</span>

            <span class="text-muted mt-1 fw-bold fs-7"
              >Specify a job's details</span
            >
          </h3>
        </div>
        <div class="card-body py-3 px-0 mt-2">
          <Form
            @submit="handleFormSubmit"
            id="JobDetailsCardForm"
            ref="job_details_form"
            class="form"
            :validation-schema="validationSchema"
          >
            <div class="row mb-4 ms-3 me-3">
              <div class="col-6 mt-4">
                <label class="required fs-6 fw-bold mb-2">Name</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Give your job a name"
                  name="name"
                  v-model="JobData.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="name" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="required fs-6 fw-bold mb-2">City</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter city"
                  name="city"
                  v-model="JobData.city"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="city" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Venue</label>
                <PreferredVenuesDropdown
                  ref="prefferedVenuesDropdown"
                  @selected-venue="handleSelectVenue"
                  :client-id="companyId"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage
                      v-if="!isEditingTemplate"
                      name="country_dropdown"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Country</label>
                <CountriesDropdown
                  ref="countriesDropdown"
                  :defaultValue="JobData.country"
                  :name="'country_dropdown'"
                  @change="
                    id => {
                      JobData.country = id;
                    }
                  "
                ></CountriesDropdown>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage
                      v-if="!isEditingTemplate"
                      name="country_dropdown"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Entrance</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter entrance"
                  name="entrance"
                  v-model="JobData.entrance"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="entrance" />
                  </div>
                </div>
              </div>

              <div class="col-6 mt-4">
                <label class="required fs-6 fw-bold mb-2">Address</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter address"
                  name="address"
                  v-model="JobData.address"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="address" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Invoice ref.</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter invoice reference"
                  name="invoice"
                  v-model="JobData.invoice"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="invoice" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Postal code</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter postal code"
                  name="postal"
                  v-model="JobData.postal"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="postal" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label class="fs-6 fw-bold mb-2">Area/District</label>
                <Field
                  type="text"
                  class="form-control form-control-solid"
                  placeholder="Enter area/district"
                  name="area"
                  v-model="JobData.area"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage v-if="!isEditingTemplate" name="area" />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <div class="w-100 d-flex flex-column">
                  <label class="fs-6 fw-bold mb-2">Job Dresscode</label>
                  <Field v-slot="{ field }" name="dresscode">
                    <el-select
                      v-model="JobData.dresscode"
                      v-bind="field"
                      class="w-100"
                      placeholder="Select job dresscode"
                      size="large"
                      clearable
                    >
                      <el-option
                        v-for="dresscode in dresscodes"
                        :key="dresscode.id"
                        :label="dresscode.name"
                        :value="dresscode.id"
                      >
                      </el-option>
                    </el-select>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="dresscode"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4 ms-3 me-3">
              <div class="col-12">
                <label class="fs-6 fw-bold mb-2">Overral job description</label>
                <textarea
                  class="form-control form-control-solid"
                  placeholder="Please give us as many details for the job as you can"
                  rows="4"
                  v-model="JobData.details"
                ></textarea>
              </div>
            </div>
            <div class="row mb-4 ms-3 me-3">
              <div class="col-12" v-if="!canEditPmAndCompany">
                <label class="fs-6 fw-bold mb-2"
                  >Select a crew handler</label
                >
                <CHDropdown
                  ref="PMDropdown"
                  @change="handlePMChange"
                ></CHDropdown>
              </div>
              <div
                class="col-12"
                :class="canEditPmAndCompany ? '' : 'mt-4'"
                v-if="!isEditingTemplate"
              >
                <div class="row">
                  <div v-if="JobData.company != ''" class="col-6">
                    <label class="fs-6 fw-bold mb-2"
                      >Your onsite contact for the Wanted crew</label
                    >
                    <ColsDropdown
                      :disabled="!companyId"
                      @change_col="
                        id => {
                          JobData.COLid = id;
                        }
                      "
                      :initialValue="JobData.COLid"
                      @clear="JobData.COLid = ''"
                      @create_new_col="handleCreateNewCol"
                    ></ColsDropdown>
                  </div>
                  <div :class="JobData.company == '' ? 'col-12' : 'col-6'">
                    <label class="fs-6 fw-bold mb-2">Upload files</label>
                    <input
                      type="file"
                      class="form-control"
                      id="fileUpload"
                      aria-describedby="fileUploadField"
                      aria-label="Upload"
                    />
                  </div>
                </div>
              </div>
              <div
                class="col-12 mt-4"
                v-if="$can('can_select_job_booker', 'all')"
              >
                <div class="col-12 col-md-6">
                  <label class="fs-6 fw-bold mb-2"
                    >Select a Booker for the job</label
                  >
                  <SearchJobBookerDropdown
                    ref="searchBookerDropdown"
                    @cleared="handleSelectJobBooker(null)"
                    @value-change="handleSelectJobBooker"
                  />
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import CountriesDropdown from "@/components/dropdown/CountriesDropdown.vue";
import BranchesDropdown from "@/components/dropdown/VeeBranchesDropdown.vue";
import ColsDropdown from "@/components/dropdown/ColsDropdown.vue";
import CreateNewColModal from "@/components/jobs/Create/Misc/CreateNewColModal.vue";
import { Modal } from "bootstrap";
import {mapActions, mapGetters} from "vuex";
import SearchJobBookerDropdown from "@/components/dropdown/SearchJobBookerDropdown.vue";
import PreferredVenuesDropdown from "@/components/jobs/Misc/PreferredVenuesDropdown.vue";
import dresscodes from "@/store/modules/Dresscodes";
import CHDropdown from "@/components/dropdown/CrewHandlersDropdown.vue";

export default defineComponent({
  name: "job-details-card",
  props: {
    isEditingTemplate: {
      type: Boolean,
      default: false
    },
    isUsingTemplate: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CHDropdown,
    PreferredVenuesDropdown,
    SearchJobBookerDropdown,
    CountriesDropdown,
    ColsDropdown,
    CreateNewColModal,
    ErrorMessage,
    Field,
    Form
  },
  data() {
    return {
      JobData: {
        name: "",
        country: "",
        city: "",
        entrance: "",
        invoice: "",
        address: "",
        details: "",
        projectManager: "",
        postal: "",
        COLid: "",
        area: "",
        venueId: "",
        bookerId: "",
        dresscode: ""
      },
      modal: null,
      modalKey: 0,
      branchesSelectErrorLabelIsShown: false,
      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required()
          .label("Name"),
        city: Yup.string()
          .required()
          .label("City"),
        address: Yup.string()
          .required()
          .label("Address"),
      })
    };
  },
  computed: {
    dresscodes() {
      return dresscodes
    },
    ...mapGetters('JobCreateModule', ['shifts', "selectedCompany"]),
    ...mapGetters("ListsModule", ["venues", "countries", "dresscodes"]),
    ...mapGetters({currentUser : "currentUser" }),
    canEditPmAndCompany() {
      if(this.$can("job_create_can_edit_company", "all") || this.$can("job_create_can_edit_pm", "all")) {
        return false;
      } else {
        return true;
      }
    },
    companyId() {
      return this.selectedCompany;
    },
    venueId() {
      return this.JobData.venueId;
    }
  },
  mounted() {
    if(this.canEditPmAndCompany) {
      this.populateFieldsForBookers();
    }
    if(this.dresscodes.length == 0) {
      this.fetchDresscodes();
    }
  },
  watch: {
    companyId() {
      if (this.companyId) {
        this.fetchCompanyCols(this.companyId)
      } else {
        this.clearCols();
      }
    },
    venueId() {
      if(this.JobData.venueId) {
        if (this.isUsingTemplate) {
          return;
        }
        this.fetchSingleVenue(this.JobData.venueId).then(venueData => {
          this.handlePopulateAddressRelatedFields(
            venueData
          );
        });
      }
    }
  },
  methods: {
    ...mapActions("PriceEstimationModule", ['setClientID', "setBranchesID"]),
    ...mapActions("JobCreateModule", ["createJob", "setJobData"]),
    ...mapActions("ListsModule", ["fetchCompanyCols", "clearCols", "fetchSingleVenue", "fetchDresscodes"]),
    handleClear() {
      this.clearCols();
      this.JobData.COLid = "";
    },
    handleSelectVenue(venueId) {
      this.JobData.venueId = venueId;
    },
    async handleCreateNewCol() {
      this.modalKey++;
      await nextTick();
      this.modal = new Modal(document.getElementById('create_new_col_modal'));
      this.modal.show();
    },
    populateFieldsForBookers() {
      if(this.$can("view_only_own_company_jobs")) {
        this.JobData.company = this.currentUser.company_id;
        if(this.currentUser.branches.length > 0) {
          const primaryBranch = this.currentUser.branches.find(branch => branch.pivot.is_primary == 1);
          this.JobData.branches = [primaryBranch.id];
        }
      }
    },
    getTemplateData() {
      return new Promise((resolve) => {
        resolve(this.JobData);
      });
    },
    populateFieldsFromTemplate(data) {
      Object.keys(data).forEach((key) => {
        if(key in this.JobData) {
          this.JobData[key] = data[key];
          if("projectManager" === key) {
            if(this.$refs.PMDropdown !== undefined) {
              this.$refs.PMDropdown.setData(data[key]);
            }
          }
          if("bookerId" === key) {
            if(this.$refs.searchBookerDropdown !== undefined) {
              this.$refs.searchBookerDropdown.setDataById(data[key]);
            }
          }
        }
      })
      if(!data.venueId) {
        this.$refs.prefferedVenuesDropdown.resetField();
      } else {
        this.$refs.prefferedVenuesDropdown.setValue(data.venueId);
      }
    },
    handlePopulateAddressRelatedFields(venueData) {
      if(venueData){
        this.JobData.city = venueData.city;
        this.JobData.country = venueData.country ? venueData.country.id : null;
        this.JobData.entrance = venueData.entrance;
        this.JobData.address = venueData.address;
        this.JobData.postal = venueData.zip;
        this.JobData.area = venueData.province;
        this.$refs.countriesDropdown.value = venueData.country ? venueData.country.title : null;
      } else {
        this.handleMultiDropdownClear();
      }
    },
    handlePMChange(data) {
      this.JobData.projectManager = data;
    },
    async handleCreatedCol(data) {
      await this.fetchCompanyCols(this.companyId);
      this.JobData.COLid = data.id;
      this.modal.hide();
    },
    handleSelectJobBooker(value) {
      this.JobData.bookerId = value;
    },
    handleMultiDropdownClear() {
      this.JobData.city = "";
      this.JobData.country = "";
      this.JobData.entrance = "";
      this.JobData.address = "";
      this.JobData.postal = "";
      this.JobData.area = "";
      this.branchesSelectErrorLabelIsShown = true;
    },
    handleFormSubmit() {
      this.validationSchema.isValid(this.JobData).then(validationResult => {
        if (validationResult) {
          this.setJobData(Object.assign({}, this.JobData))
          this.createJob();
          this.branchesSelectErrorLabelIsShown = false;
        }
      });
    },
    getDataForTemplate() {
      return new Promise((resolve) => {
          resolve(this.JobData);
      });
    },
    validateInputs() {
    return new Promise((resolve, reject) => {
      this.$refs.job_details_form.validate();
      this.validationSchema.isValid(this.JobData).then(validationResult => {
        if (validationResult) {
          resolve(this.JobData);
        }else {
          reject(new Error('Invalid job details'));
        }
        })
      });
    }
  }
});
</script>
