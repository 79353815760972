<template>
  <div>
    <el-select
      name="branches"
      v-model="inputValue"
      filterable
      clearable
      size="large"
      multiple
      @clear="$emit('clear')"
      placeholder="Select branches"
      class="w-100"
      @remove-tag="removeTag"
    >
      <el-option
        @click="updateValue(item.id)"
        v-for="item in branches"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import store from "@/store/index";
import { computed } from "vue";
import { mapActions } from "vuex";

export default {
  props: {
    initialValue: {
      type: [Number, String, Array]
    },
    name: {
      type: String,
      required: true
    }
  },
  methods:{
    ...mapActions("ListsModule", ["fetchBranches"])
  },
  mounted() {
    this.fetchBranches();
  },
  watch: {
    initialValue() {
      this.inputValue = this.initialValue;
    }
  },
  setup(props, { emit }) {
    const branches = computed(() => store.getters["ListsModule/branches"]);
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
      meta
    } = useField(props.name, undefined, {
      initialValue: props.initialValue
    });
    const updateValue = () => {
      emit("input_event", inputValue.value);
    };
    const removeTag = (id) => {
      for(const i in inputValue.value) {
        if(inputValue.value[i] == id) {
          delete inputValue.value[i];
        }
      }
      emit("input_event", inputValue.value);
    };
    return {
      updateValue,
      removeTag,
      handleChange,
      handleBlur,
      errorMessage,
      inputValue,
      meta,
      branches
    };
  }
};
</script>
